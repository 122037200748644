import React from "react";

import Grid from '@mui/material/Grid2/index.js';
import Container from "@mui/material/Container/index.js";
import parnters from './img/partners2.png';
import Typography from '@mui/material/Typography/index.js';
import Box from '@mui/material/Box/index.js';

// import prog1 from './img/prog1.png';
// import prog2 from './img/prog2.png';
// import prog3 from './img/prog3.png';


const testStyle = {
  opacity:.7
  // "font-size": "12px"
  // background: "#7fcdbb"
};


export default function Footer() {
   

  return (
    <>


    <Container maxWidth="lg" style={{ paddingTop: '350px'}}>

    {/* <Container  maxWidth="xl" style={{"paddingTop":"180px","paddingBottom":"10px",'opacity':'.7'}}>
      <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
  
        <Grid item xs={12} sm={6} md={3} style={testStyle}>
        <a href="https://courses.kse.ua/home/procurement-management" target="_blank">
            <img width='85%' src={prog1}></img>
          </a>
        </Grid>
            
        <Grid item xs={12} sm={6} md={3} style={testStyle}>
          <a href="https://courses.kse.ua/home/pp-specialist " target="_blank">
            <img width='85%' src={prog2}></img>
          </a>
        </Grid>  

      </Grid>
    </Container> */}

 

      <Grid container spacing={2}>

          <Grid size={{ xs: 12, lg: 4 }} style={testStyle}>
            <img id='partners' src={parnters} style={{width: "300px",paddingTop:"0px"}}></img>
            <Typography variant="caption" display="block" gutterBottom>
            Цей ресурс створено в рамках проекту "Прозорість та підзвітність у державному управлінні та послугах" за фінансування USAID та UKAID
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, lg: 4 }} style={{paddingTop:"15px",opacity:.7}}>
            <Typography variant="caption" display="block" gutterBottom>
             Центр вдосконалення закупівель Kyiv School of Economics.  
             Київ, вул. Шпака, 3, Email: ksetools@kse.org.ua
            </Typography>
          </Grid>
          
            <Grid size={{ xs: 12, lg: 4 }} style={testStyle}>
              <Typography variant="caption" display="block" gutterBottom>
                <p>Інші проекти СЕР</p>
                <div><a style={{color:"#555"}} href="https://cepjobs.kse.ua/">Портал вакансій в закупівлях</a></div>
                <div><a style={{color:"#555"}} href="https://cep.kse.ua/osoba">Уповноважена особа</a></div>
                
              </Typography>
            
            {/* <div className="footerColor">
              <Typography variant="caption" display="block" gutterBottom>
                <p>Інші проекти СЕР</p>
              <div><a style={{color:"#555"}} href="https://cep.kse.ua/scheme">Інтерактивні схеми закупівель</a></div>
              <div><a style={{color:"#555"}} href="https://cep.kse.ua/osoba">Уповноважена особа</a></div>
              </Typography>
              </div> */}
          </Grid>
        </Grid>
        
      </Container>
    
      </>
  );
}



import React from "react";

// import { styled } from '@mui/material/styles';
import AppBar from "@mui/material/AppBar/index.js";
import Toolbar from "@mui/material/Toolbar/index.js";
import Typography from '@mui/material/Typography/index.js';
// import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu/index.js';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem/index.js';
import IconButton from '@mui/material/IconButton/index.js';

import { Link } from 'react-router-dom';
import logo from './img/logo_black.png';



// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// }));

export default function Header(props) {
  const [anchorElm, setAnchorElm] = React.useState(null);
  // const [typeedr, setTypeedr] = React.useState('');
  // const [isloaded, setIsloaded] = React.useState(false);
  // const [checked, setChecked] = React.useState(false);

  const handleClick = (event) => {
    setAnchorElm(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElm(null);
  };

  return (
    <div>
      <AppBar style={{ background: "#3fd4ad", color: "#fff" }} position="static">
        <Toolbar>
          <Typography variant="h6">
            <Link to="/">
              <img src={logo} width='120px;' alt="Logo" />
            </Link>
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton color="inherit" onClick={handleClick}>
              <MenuIcon sx={{ color: '#000' }}/>
            </IconButton>

            
            <Menu
              id="long-menu"
              anchorEl={anchorElm}
              keepMounted
              open={Boolean(anchorElm)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Link style={{ color: "#222" }} to="/">Головна</Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link style={{ color: "#222" }} to="/about">Про ресурс і дані</Link>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import React from "react";

import Box from "@mui/material/Box/index.js";
import Container from "@mui/material/Container/index.js";
import TextField from '@mui/material/TextField/index.js';
import Button from '@mui/material/Button/index.js';
import Typography from '@mui/material/Typography/index.js';

import axios from "axios";
import { AuthContext } from "./index.js";
import queryString from 'query-string';

const testStyle = {
  border: "1px solid rgb(63, 212, 173)"
};

// const useStyles = styled((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       width: '100%',
//     },
//   },
// }));

export default function Search(props) {
  const [isloaded, setIsloaded] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [count, setCount] = React.useState();
  const [error, setError] = React.useState("");
  const context = React.useContext(AuthContext);

  React.useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    setSearch(parsed.search || "");
    context.setSer(parsed.search || "");
  }, []);

  const handleChange = (e) => {
    const sanitizedValue = e.target.value.replace(/%/, '');
    setSearch(sanitizedValue);
    context.setSer(sanitizedValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    window.history.pushState(null, "Page Title", "/?search=" + search);
    getdata(search);
  };

  function getdata(search) {
    setIsloaded(true);
    setError('')
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/q?search=${search}`)
      .then((res) => {
        !res.data&&setError('Нічого не знайдено')
        setIsloaded(false);
        props.setData(res.data);
        setCount(res.data.spending?.searchCountCpv);
      })
      .catch((error) => {
        setIsloaded(false);
        console.error('Error fetching data:', error);
      });
  }


  return (
    <div style={{ background: "#3fd4ad", paddingBottom: '50px' }}>
      <Container maxWidth="sm" style={testStyle}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
            <Box flexGrow={8} flexShrink={8} flexBasis={9} style={testStyle}>
              <TextField 
                id="standard-basic" 
                label="Код ДК за назвою товару чи послуги" 
                value={search}
                fullWidth
                size="small"
                // variant="standard"
                onChange={handleChange}
              /> 
            </Box>
            <Box flexGrow={4} flexShrink={3} flexBasis={2} style={testStyle}>
              <Button
                type="submit"
                style={{ marginTop: "15px" }}
                variant="contained" 
                color="primary"
                sx={{ ml: 2,pb: 1 }}
              >
                {isloaded ? (
                  <><span className="simpleSpinner"></span>Шукаю</>
                ) : (
                  "Шукати"
                )}
              </Button>
            </Box>
          </Box>
        </form>
      </Container>
      
      <Container maxWidth="sm" style={testStyle}>
      <Box sx={{height: '30px'}}>
      {<Typography sx={{ color: '#de2d26' }} variant="overline" gutterBottom>
            {error}
        </Typography> || ''} 
      </Box>

      </Container>

    
    </div>
  );
}

import React from 'react';
// import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper/index.js';
import Table from '@mui/material/Table/index.js';
import TableBody from '@mui/material/TableBody/index.js';
import TableCell from '@mui/material/TableCell/index.js';
import TableHead from '@mui/material/TableHead/index.js';
import TablePagination from '@mui/material/TablePagination/index.js';
import TableRow from '@mui/material/TableRow/index.js';
import Typography from '@mui/material/Typography/index.js';
// import * as d3 from 'd3';

export default function Tables(props) {
  // const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Table stickyHeader size="small" aria-label="sticky table">
        <TableHead>
          <TableRow>
            {props.data.columns.map((column, i) => (
              <TableCell
                key={i}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  <TableCell align="left">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.documentDate.replace(/T.+/,'')}
                      {/* {d3.timeParse('%Y-%m-%d')(row.documentDate)} */}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">{row.specificationName}</TableCell>
                  <TableCell align="left">
                    <a
                      href={
                        'https://spending.gov.ua/new/disposers/' +
                        row.buyer +
                        '/agreements/' +
                        row.id
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.itemCostPdv.toFixed(2)}
                    </a>{' '}
                    {row.itemDimension}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 100]}
        component="div"
        count={props.data.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

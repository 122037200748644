import React from "react";
import Box from "@mui/material/Box/index.js";
import Container from "@mui/material/Container/index.js";
import List from '@mui/material/List/index.js';
import ListItem from '@mui/material/ListItem/index.js';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction/index.js';
import ListItemText from '@mui/material/ListItemText/index.js';
import Divider from '@mui/material/Divider/index.js';
import Typography from '@mui/material/Typography/index.js';
// import { Link } from 'react-router-dom';
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import Grid from '@mui/material/Grid2/index.js';
import SwipeDownOutlinedIcon from '@mui/icons-material/SwipeDownOutlined';

const testStyle = {
  border: "1px solid #ccc",
  background: "#fff"
};

export default function Step1(props) {
  
  const containerRef = React.useRef();
  const containerRef2 = React.useRef();

  




  React.useEffect(() => {
    if (props == undefined) return;
    let cpv = d3.flatGroup(props.data.cpvTop.map(d=>{
    return {
      ...d,
      one: d._id.slice(0,4),
       five: d._id.slice(4,5),
       six: d._id.slice(5,6),
      seven: d._id.slice(6,7),
      eight: d._id.slice(7,8)
    }
  }),d=>d.one,d=>d.five,d=>d.six,d=>d.seven,d=>d.eight)
  .map(d=>{
    return {
      // ...d,
      name:' Код ДК/'+d[0]+'0000/'+d[0]+d[1]+'000/'+d[0]+d[1]+d[2]+'00/'+d[0]+d[1]+d[2]+d[3]+'0/'+d[0]+d[1]+d[2]+d[3]+d[4]+'',
      size:d[5][0].totalCpv,
      cpv_description:d[5][0].cpv_description
    }
  })
    .map(d=> {
    return{
      ...d,
      name:[...new Set(d.name.split('/'))].join('.')
    }
  })
  
  // console.log('cpv',cpv)
    
    const plot = Plot.plot({
      style: { fontSize: 13 },
      axis: null,
      height: 400,
      marginRight: 40,
      marginLeft: 70,
      marginTop: 30,
      marginBottom: 20,
      width: 450,
      marks: [
        Plot.link(cpv, Plot.treeLink({path: "name",delimiter: ".",stroke: "node:internal",strokeWidth:3})),
        Plot.dot(cpv, Plot.treeNode({path: "name",delimiter: ".",r:d=>d?.size?d.size:null,tip:true,title:d=>`Назва: ${d?.cpv_description?d.cpv_description:null}\nКількість: ${d?.size} специфікацій`,fill:'#3fd4ad',opacity:1})),
        // Plot.tree(cpv, {path: "name",delimiter: ".",textStroke: "white",strokeWidth:3}),
        Plot.text(cpv, Plot.treeNode({path: "name",text: "node:name",delimiter: ".", stroke: "white", fill: "currentColor", textAnchor: "end", dx: -6}))
      ]
    })
    containerRef.current.append(plot);
    return () => plot.remove();
  }, []);





  
   
  React.useEffect(() => {
    if (props == undefined) return;
    let cpv2 = d3
    .flatRollup(
      props.data.words.map((d) => d.toLowerCase().split(" ")),
      (v) => v.length,
      (d) => d[0],
      (d) => d[1],
      (d) => d[2]
    )
    .filter((d) => d[0] != "")
    .filter((d) => d[0] != "undefined")
    .map((d) => {
      return {
        name: "Закупівля:." + d[0] + "." + d[1] + "." + d[2],
        size: d[3]
      };
    })
    .map((d) => {
      return {
        ...d,
        name: d.name.replace(/undefined/g, "")
      };
    })
    .sort((a, b) => b.size - a.size)
    .slice(0, 20)
  
    // console.log('cpv2',cpv2)
    
    const plot2 = Plot.plot({
      style: { fontSize: 13 },
      axis: null,
      marginRight: 40,
      marginLeft: 70,
      marginTop: 30,
      marginBottom: 20,
      width: 450,
      height: 400,
      marks: [
        Plot.link(
          cpv2,
          Plot.treeLink({
            path: "name",
            delimiter: ".",
            stroke: "node:internal",
            strokeWidth: 3
            // stroke: "#aaa"
          })
        ),
        Plot.dot(
          cpv2,
          Plot.treeNode({
            path: "name",
            delimiter: ".",
            r: (d) => (d?.size ? d.size : null),
            tip: true,
            title:d=>`${d?.name?d.name:null}`,
            fill: "#3fd4ad",
            opacity: 1
          })
        ),
        Plot.text(
          cpv2,
          Plot.treeNode({
            path: "name",
            text: "node:name",
            delimiter: ".",
            stroke: "white",
            fill: "currentColor",
            textAnchor: "end",
            dx: -6
            // treeFilter: "node:internal"
          })
        )
      ]
    })
    containerRef2.current.append(plot2);
    return () => plot2.remove();
  }, []);



  // console.log('STEP1: ', props);

  return (
    <>
      <Container maxWidth="lg" style={{ paddingTop: "10px" }}>
        <Box display="flex" flexDirection="row">
          <Typography variant="overline" display="block" gutterBottom style={{ lineHeight: 1.1, color: 'grey' }}>
            {props.data.searchCountCpv && 'На основі аналізу ' + 
            props.data.searchCountCpv
           + ' специфікацій'} з сайту spending.gov.ua
          </Typography>
        </Box>
      </Container>

      


      <Container maxWidth="lg" style={{ paddingBottom: '20px', paddingTop: '10px' }}>
        
      {/* <Box lg={{ flexGrow: 1 }}> */}
      <Grid container spacing={2}>

        <Grid size={{ xs: 12, lg: 6 }} style={testStyle}>
            <List component="nav" aria-label="main mailbox folders" dense>
              <Typography variant="h6" gutterBottom style={{ paddingLeft: '10px' }}>
                Популярні cpv
              </Typography>
              <Typography variant="caption" gutterBottom style={{ paddingLeft: '10px' }}>
                Статистика використання cpv/ДК кодів
              </Typography>
              {props.data.cpvTop.map((d, i) => (
                <div key={i} className={'cpv-' + d._id}>
                  <ListItem>
                    <ListItemText 
                      primary={d._id} 
                      secondary={
                        <>
                          <div>{d.cpv_description}</div>
                          <Typography variant="caption" display="block" gutterBottom>
                            <div className={'expv-' + d._id}></div>
                          </Typography>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      {d.share.toFixed() + "%"}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
              ))}              
            </List>
        </Grid>

       
        <Grid size={{ xs: 12, lg: 6 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, lg: 12 }} style={testStyle}>
            <Typography variant="h6" gutterBottom style={{ paddingLeft: '10px' }}>
              Дерево спв-кодів
            </Typography>
            <Typography variant="caption" gutterBottom style={{ paddingLeft: '10px' }}>
              Візуальна ієрархія використання cpv/ДК кодів
            </Typography>
            <div ref={containerRef} />
            <Typography variant="caption" gutterBottom style={{ paddingLeft: '10px' }}>
            <SwipeDownOutlinedIcon style={{opacity:.5}} fontSize='small'/>  Використовуйте мишку, щоб побачити значення
            </Typography>
            
            </Grid>
            <Grid size={{ xs: 12, lg: 12 }} style={testStyle}>
            <Typography variant="h6" gutterBottom style={{ paddingLeft: '10px' }}>
              Дерево слів
            </Typography>
            <Typography variant="caption" gutterBottom style={{ paddingLeft: '10px' }}>
                Візуальне представлення описів товарів/послуг в контрактах/специфікаціях
            </Typography>
            <div ref={containerRef2} />
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      {/* </Box> */}
    


        <Typography variant="caption" gutterBottom>
          <a target="_blank" rel="noopener noreferrer" style={{ color: 'grey' }} href={"https://infobox.prozorro.org/articles/poryadok-viznachennya-predmeta-zakupivli"}>
            *Див. порядок визначення предмету закупівлі.
          </a>
        </Typography>
        <p></p>
      </Container>
    </>
  );
}

import React, { useState, useEffect } from "react";
import "./styles.css";

import Tabs from '@mui/material/Tabs/index.js';
import Tab from '@mui/material/Tab/index.js';

import Search from "./Search.js"
import Step1 from "./Step1.js"
import Step2 from "./Step2.js"

export default function App() {
  const [data, setData] = useState(null);
  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  function RenderSteps({ tab }) {
    switch (tab) {
      case 0:
        return <Step1 data={data} />;
      case 1:
        return <Step2 />;
      default:
        return null;
    }
  }

  // console.log('APP',tab,data)
  
  return (
    <div className="App">
      <Search setData={setData} data={data} />
      {data && (
        <>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Cpv" />
            <Tab label="Ціни" />
          </Tabs>
          <RenderSteps tab={tab} />
          
        </>
       
      )}

    </div>
  );
}
import React from "react";
import { Container, Divider, Typography, Switch, FormControlLabel, Tooltip, Slider, Input, ButtonGroup, Button, Box } from "@mui/material/index.js";
import Grid from '@mui/material/Grid2/index.js';
import axios from "axios";
import * as d3 from "d3";
import * as Plot from "@observablehq/plot";
import Table from "./Table2.js";
import { AuthContext } from "./index.js";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const testStyle = {
  border: "1px solid #ccc",
  background: "#fff"
};

const columns = [
  { id: 'cpv', label: 'Дата угоди' },
  { id: 'code', label: 'Опис'},
  { id: 'price', label: 'Ціна за од. з ПДВ'},
];

export default function Step2() {
  const [dataset, setDataset] = React.useState();
  const [limit, setLimit] = React.useState(1);
  const [slider, setSlider] = React.useState(7);
  const [wi, setWi] = React.useState(400);
  const [binwi, setBinwi] = React.useState(400);
  const [tender, setTender] = React.useState(false);
  const [trend, setTrend] = React.useState(false);
  const [isloaded, setIsloaded] = React.useState(false);
  const [dimension, setDimension] = React.useState('');
  const [word, setWord] = React.useState('');
  const [reduce, setReduce] = React.useState(false);
  const [out, setOut] = React.useState(false);
  const [minmax, setMinmax] = React.useState({ min: 1, max: 10000 });
  const [error, setError] = React.useState("");

  const ref = React.useRef(null);
  const ref2 = React.useRef(null);
  const context = React.useContext(AuthContext);



  function getdata(ser) {
    setError('')
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/q2?search=${ser}&limit=${limit}&tender=${tender}&dimension=${dimension}&min=${minmax.min}&max=${minmax.max}`
      )
      .then(res => {
        setIsloaded(false);
        !res.data&&setError('Замало даних для відображення')
        try {
          res.data.forEach(e => {
            // e.documentDate = d3.timeParse("%Y-%m-%d")(e.documentDate);
            if (!e.isCpvVat && !e.procurementItems.includes('336')) {
              e.itemCostPdv = e.itemCost * 1.2;
            } else if (!e.isCpvVat && e.procurementItems.includes('336')) {
              e.itemCostPdv = e.itemCost * 1.07;
            } else {
              e.itemCostPdv = e.itemCost;
            }
          });
          let median = d3.median(res.data, d => d.itemCostPdv);
          setDataset(res.data.filter(d => (d.itemCostPdv > median * 0.2) && (d.itemCostPdv < median * 1.8)));
        } catch (error) {
          setDataset(undefined)
        }
   
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }




  React.useEffect(() => {
    setIsloaded(true);
    let timer = setTimeout(() => getdata(context.ser), 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [limit, tender, dimension, minmax]);





  React.useEffect(() => {
    if (dataset === undefined) return;
    let median = d3.median(dataset, d => d.itemCostPdv);
    let dataset2 = dataset.filter(d => word.length >= 3 ? !d.specificationName.toLowerCase().includes(word.toLowerCase()) : d.specificationName && out ? (d.itemCostPdv > median * 0.6 && d.itemCostPdv < median * 1.4) : (d.itemCostPdv > median * 0.2 && d.itemCostPdv < median * 1.8));

    d3.timeFormatDefaultLocale({
      dateTime: "%A, %e %B %Y р. %X",
      date: "%d.%m.%Y",
      time: "%H:%M:%S",
      periods: ["дп", "пп"],
      days: [
        "неділя",
        "понеділок",
        "вівторок",
        "середа",
        "четвер",
        "п'ятниця",
        "субота"
      ],
      shortDays: ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
      months: [
        "січня",
        "лютого",
        "березня",
        "квітня",
        "травня",
        "червня",
        "липня",
        "серпня",
        "вересня",
        "жовтня",
        "листопада",
        "грудня"
      ],
      shortMonths: [
        "січ",
        "лют",
        "бер",
        "квіт",
        "трав",
        "черв",
        "лип",
        "серп",
        "вер",
        "жов",
        "лист",
        "груд"
      ]
    });

    const chart = Plot.plot({
      style: {
        background: "transparent",
        fontSize:13
      },
      width: wi * 0.99,
      height: 400,
      y: {
        domain: [0, d3.max(dataset, d => d.itemCostPdv) * 1.1],
        label: 'Ціна, грн з ПДВ'
      },
      x: {
        label: 'Дата підписання угоди',
        type:'time',
        // tickFormat: Plot.formatMonth("ua", "short")
      },
      grid: true,
      marginBottom:40,
      marginTop:40,
      marks: [
        Plot.linearRegressionY(dataset2, { x: "documentDate", y: "itemCostPdv", stroke: "#3fd4ad", strokeWidth: 2, ci: 0.95, opacity: trend ? 1 : 0 }),
        Plot.dot(dataset2, {
          x: "documentDate",
          y: "itemCostPdv",
          fill: 'blue',
          r:3.5,
          // stroke: "#333",
          opacity: .4,
          tip:true,
          title: d => `Опис: ${d.specificationName}\nЦіна (з ПДВ): ${d.itemCostPdv.toFixed(2)} грн/${d.itemDimension}`
        }),
        Plot.line(
          dataset2,
          Plot.windowY({
            k: slider,
            reduce: reduce ? 'median' : 'mean'
          }, {
            x: "documentDate",
            y: "itemCostPdv",
            curve: "step",
            stroke: "blue",
            opacity: 0.8
          })
        )
      ]
    });
    if (!isloaded) ref.current.append(chart);
    return () => chart.remove();
  }, [dataset, trend, slider, word, reduce, out]);




  
  
  React.useEffect(() => {
    if (dataset === undefined) return;
    let median = d3.median(dataset, function(d) { return d.itemCostPdv; })
    let dataset2 = dataset.filter(d=>word.length>=3?!d.specificationName.toLowerCase().includes(word.toLowerCase()):d.specificationName && out?(d.itemCostPdv>median*.6&&d.itemCostPdv<median*1.4):(d.itemCostPdv>median*.2&&d.itemCostPdv<median*1.8))

    const chart2 = Plot.plot({
      style: {
        background: "transparent",
        fontSize:13
      },
      width:binwi*.9,
      height:400,
      y: {
        domain:[0,d3.max(dataset,d=>d.itemCostPdv)*1.1],
        label: 'Ціна, грн з ПДВ'
      },
      x: {
        label: 'Кільксть угод',
      labelAnchor:'center'
      },
      marginBottom:40,
      marginTop:40,
      grid: true,
      color: {
        type: "diverging",
        scheme: "burd",
        // legend:true
      },
      marks: [
        Plot.ruleX([0]),
        Plot.rectX(dataset2, Plot.binY({ x: "count" }, { y: "itemCostPdv",fill:'#ddd',stroke:'#333' }))
      ]
    });
    !isloaded&&ref2.current.append(chart2);
    return () => chart2.remove();
  }, [dataset,trend,word,out]);






  React.useEffect(() => {
    setWi(parseInt(d3.select("#graphContainer").style("width")));
    setBinwi(parseInt(d3.select("#binContainer").style("width")));
  }, []);





  // console.log('STEP2',dataset)


  // if (!dataset || dataset.length === 0) {
  //   return null; // Нічого не рендеримо
  // }

  return (
    <>

        <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
          <Typography sx={{ color: '#de2d26' }} variant="overline" gutterBottom>
            {error}
          </Typography>
        </Grid>
      

      <Container maxWidth="lg" style={{ paddingTop: "10px" }}>
        <Grid container spacing={1}>
          <Typography variant="overline" display="block" gutterBottom style={{ lineHeight: 1.1, color: 'grey' }}>
           Використовуйте панель справа, щоб уточнити дані і аналізувати їх.
          </Typography>
        </Grid>
      </Container>


      <Container maxWidth="lg" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <Grid container spacing={2}>
          
          <Grid size={{ xs: 12, md: 7 }} id="graphContainer" style={testStyle}>
            {!isloaded ?
              <svg id="svg" width={wi} height={420} ref={ref} /> :
              <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: "170px" }}>
                  <span className="simpleSpinner"></span>
                </Grid>
              </Container>
            }
          </Grid>

          <Grid size={{ xs: 12, md: 2 }}  id="binContainer" style={testStyle}>
            {!isloaded ?
              <svg width={binwi} height={420} ref={ref2} /> :
              <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: "170px" }}>
                  <span className="simpleSpinner"></span>
                </Grid>
              </Container>
            }
          </Grid>




          <Grid size={{ xs: 12, md: 3 }} style={{
            background: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}>
            <Grid container>
              <Grid size={{ xs: 12, md: 12 }}>
                <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                  За {limit} міс
                  <Tooltip title={'Спочатку ви бачите дані за останній місяць. Ви можете розширити пошук на 3 місяці або останні півроку.'}>
                    <InfoOutlinedIcon fontSize="small" style={{ color: '#aaa' }} />
                  </Tooltip>
                </Typography>
                <ButtonGroup size="small">
                  <Button onClick={() => setLimit(1)}>
                    <Typography variant="caption" gutterBottom>місяць</Typography>
                  </Button>
                  <Button onClick={() => setLimit(3)}>
                  <Typography variant="caption" gutterBottom>три</Typography>
                  </Button>
                  <Button onClick={() => setLimit(6)}><Typography variant="caption" gutterBottom>півроку</Typography></Button>
                </ButtonGroup>
              </Grid>

            </Grid>

            <Divider style={{ marginTop: '10px', marginBottom: '5px' }} />

              <Grid container>
                <Grid size={7}>
                    <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                    Ціна від {minmax.min} до {minmax.max}
                      <Tooltip title={'Вкажіть власний ціновий діапазон в грн з ПДВ, щоб обмежити результати.'}>
                      <InfoOutlinedIcon fontSize="small" style={{ color: '#aaa' }} />
                      </Tooltip>
                    </Typography>
                  <Grid container spacing={.5}>
                  <Grid size={1}>
                    {/* <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                      Від:
                    </Typography> */}
                  </Grid>
                  <Grid size={5}>
                    <Input name='min' value={minmax.min} onChange={(e) => setMinmax({ ...minmax, min: e.target.value })} placeholder="від Х грн" fullWidth  size="small"/>
                  </Grid>
                  <Grid size={1}>
                    {/* <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                      до:
                    </Typography> */}
                  </Grid>
                  <Grid size={5}>
                  <Input name='max' value={minmax.max} onChange={(e) => setMinmax({ ...minmax, max: e.target.value })} placeholder="до Х грн" fullWidth  size="small"/>
                 </Grid>
                 </Grid>
              </Grid>

            <Grid size={5}>
                <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                  Од. виміру
                  <Tooltip title={'Вкажіть одиниці виміру, наприклад, літри, штуки.'}>
                    <InfoOutlinedIcon fontSize="small" style={{ color: '#aaa' }} />
                  </Tooltip>
                </Typography>
                <Input value={dimension} onChange={(e) => setDimension(e.target.value.toLowerCase())} placeholder="шт, літр і т.п." fullWidth  size="small"/>
            </Grid>

          </Grid>

            

            <Divider style={{ marginTop: '10px', marginBottom: '5px' }} />

            <Grid container>
              <Grid size={6}>
                  <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                  Виключити слово
                  <Tooltip title={'Введіть слово, щоб виключити специфікації з ним із результатів пошуку.'}>
                    <InfoOutlinedIcon fontSize="small" style={{ color: '#aaa' }} />
                  </Tooltip>
                </Typography>
                <Input value={word} onChange={(e) => setWord(e.target.value.toLowerCase())} placeholder="текст" fullWidth />
              </Grid>
              <Grid size={6} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                  Прибрати викиди
                  <Tooltip title={'Прибрати із результатів пошуку викиди.'}>
                    <InfoOutlinedIcon fontSize="small" style={{ color: '#aaa' }} />
                  </Tooltip>
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={out}
                      onChange={(event) => setOut(event.target.checked)}
                      color="default"
                      size="small"
                    />
                  }
                  labelPlacement="end"
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: '10px', marginBottom: '5px' }} />

            <Grid container spacing={2}>
              <Grid size={8}>
                <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                  {reduce ? 'Медіана' : 'Середнє'} за {slider} днів:
                  <Tooltip title={'Показує середню чи медіану ціну (синя лінія) за обрану кількість днів.'}>
                    <InfoOutlinedIcon fontSize="small" style={{ color: '#aaa' }} />
                  </Tooltip>
                </Typography>
                <Slider
                  step={1}
                  max={30}
                  min={7}
                  defaultValue={7}
                  value={slider}
                  onChange={(e, val) => setSlider(val)}
                  size="small"
                />
              </Grid>
              <Grid size={4}>
              <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                  Сер./мед.
                  <Tooltip title={'Показую середню чи медіану ціну за обрану кількість днів.'}>
                    <InfoOutlinedIcon fontSize="small" style={{ color: '#aaa' }} />
                  </Tooltip>
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={reduce}
                      onChange={(event) => setReduce(event.target.checked)}
                      color="default"
                      size="small"
                    />
                  }
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: '10px', marginBottom: '5px' }} />

            <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
              Інші опції:
            </Typography>
            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={trend}
                      onChange={(event) => setTrend(event.target.checked)}
                      color="default"
                      size="small"
                    />
                  }
                  label={<Typography variant="caption" style={{ color: 'grey' }}>Лінія тренду</Typography>}
                />
              </Grid>
              <Grid size={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={tender}
                      onChange={(event) => setTender(event.target.checked)}
                      color="default"
                      size="small"
                    />
                  }
                  label={<Typography variant="caption" style={{ color: 'grey' }}>Тендерна процедура</Typography>}
                />
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </Container>

      <Container maxWidth="lg" style={{ paddingTop: '10px', paddingBottom: '0px', background: "none" }}>
        {dataset && <Table data={{ columns, rows: dataset }} />}
      </Container>
    </>
  );
}

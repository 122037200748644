import React from "react";

import { Container, Typography, Divider } from "@mui/material/index.js";
import Grid from '@mui/material/Grid2/index.js';
import { Link } from 'react-router-dom'

const testStyle = {
  border: "1px solid #f5f5f5",
  background:"#f5f5f5",
  marginTop:"30px",
  marginBottom:"30px"
};

export default function About() {

  return (
    <>
        <Container  maxWidth="md" style={testStyle}>
          <Grid 
          container
          direction="row"
          justify="space-between"
          alignItems="flex-end">
          <div style={{paddingTop:'20px'}}>
          <Typography variant="h5" gutterBottom  style={{paddingTop:'20px'}}>
            КРОК1. CPV. 
          </Typography>
          <Typography variant="body1" gutterBottom>
             Cpv-tool - інформаційно-аналітичний ресурс, який стане у нагоді закупівельникам при визначенні предмету закупівлі. Аналізує закупівельні специфікації і прив'язані до них спв-коди. Окремо можна поєднати цю інформацію із поточними планами вашої організації. <b>Джерело даних - spending.gov.ua.</b> Більше про дані <a style={{color:'rgb(63, 212, 173)'}} href='https://confluence.spending.gov.ua/pages/viewpage.action?pageId=10716714'>можна прочитати тут</a>
             </Typography>

             <Typography variant="body1" gutterBottom>
             Окремий розділ - <Link style={{color:'rgb(63, 212, 173)'}} to="/plans">Нейромережа</Link>. Вона натренована автоматично визначати cpv-код для швидкого і попереднього аналізу великого обсягу інформації закупівельних планів. 
            </Typography> 
            <Divider />

            <Typography variant="h5" gutterBottom  style={{paddingTop:'20px'}}>
            КРОК2. ЦІНИ. 
          </Typography>
          <Typography variant="body1" gutterBottom>
             Вказує ціни на товари. <b>Джерело даних - spending.gov.ua.</b> Це дані із контрактів (специфікації), зокрема укладених на Prozorro. Більше про дані <a style={{color:'rgb(63, 212, 173)'}} href='https://confluence.spending.gov.ua/pages/viewpage.action?pageId=10716714'>можна прочитати тут</a>
            </Typography> 
            <Divider />

            <Typography variant="h5" gutterBottom  style={{paddingTop:'20px'}}>
            КРОК3. ТД і кращі тендери. 
          </Typography>
          <Typography variant="body1" gutterBottom>
            Аналіз електронної тендерної документації і підбірка кращих тендерів серед конкурентних завершених закупівель за відповідними спв-кодами в 2020 році. Кращі тендери - це тендери, в яких переможці входять в ТОП-25 учасників ринку, а конкуренція на тендері вище за середню. Крім того, в таких тендерах обов'язково мають бути відсутніми ряд ризик-факторів: 
            {/* <ul>
                              <li>Переможець не зменшував ціну</li>
                              <li>Наявність учасника з нетипово низькими результом</li>
                              <li>Системний учасник дискваліфікований</li>
                              <li>Наявність звернень за роз'ясненнями</li>
                              <li>Закупівля близька до порогів</li>
                              <li>Ризик-фактори у запитаннях</li>
                              <li>Покращення якості предмета закупівлі</li>
                              <li>Пропозиції подані в межах години</li>
                              <li>Youcontrol: зв`язки між учасниками та замовником</li>
                              <li>Аналогічний тендер скасовано</li>
                              <li>Youcontrol: зв`язки між учасниками</li>
                              <li>Узгоджене зменшення ціни</li>
                              <li>Негативні відгуки на умови</li>
                              <li>Недетальний код класифікатору</li>
                              <li>Зменшення обсягів закупівлі</li>
                              <li>Гарантія перевищує встановлені законом норми</li>
                              <li>Зміна ціни за одиницю товару</li>
                              <li>Чисельні завантаження документів</li>
                              <li>Перетини контактної інформації учасників</li>
                              <li>Затримка публікації договору</li>
                              <li>Негативні відгуки на рішення</li>
                              <li>Зміна сторонніх показників</li>
                              <li>Масова дискваліфікація</li>
                              <li>Екстраординарний учасник не зменшував ціну</li>
                              <li>Продовження строку дії договору</li>
                              <li>Моніторинг ДАСУ</li>
                              <li>Негативні відгуки щодо відповіді</li>
                              <li>Зміна ціни у зв’язку із зміною ставок податків</li>
                              <li>Запитання без відповіді</li>
                              <li>Малий час до дати поставки</li>
                              <li>Відсутні документи</li>
                              <li>Дискваліфікація після аукціону в процедурі ЄС</li>
                              <li>Екстраординарний учасник без конкуренції</li>
                              <li>Негативні відгуки щодо звернення</li>
                              <li>Скасування за наявності скарги на умови</li>
                              <li>Продовження строку дії договору на наступний рік</li>
                            </ul> */}
                            
                             <b>Джерело даних - http://bipro.prozorro.org/</b> 
            </Typography> 
            <Divider />


          </div>
          
        </Grid>
      </Container>
      
  </>
  )
}







import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter } from 'react-router-dom'
import Header from "./Header.js";

// import { Route, Switch } from 'react-router-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from "./App.js"

import About from "./About.js"
// import Step_loko from "./Step_loko.js"
import Footer from "./Footer.js";




export const AuthContext = React.createContext();


function Index() {
  const [ser, setSer] = React.useState('');
  // const [plans, setPlans] = React.useState();
  // const [orgname, setOrgname] = React.useState('');
  // const [ser, setSer] = React.useState('');

  // console.log = console.warn = console.error = () => {};

  return (
       <AuthContext.Provider value={{
        ser,
        setSer
       }}>
        
        <Router>
      <Header />
      <Routes>
      <Route path="/" element={<App />} />
      <Route path="/about" element={<About />} />
        
      </Routes>
      <Footer />
        </Router>
      </AuthContext.Provider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Index />, rootElement);
